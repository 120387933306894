<template>
  <div class="card">
    <div class="card-body pt-0 mt-5 steps">
      <el-steps :active="active" finish-status="success">
        <el-step :title="generalConstants.DOWNLOAD_FILE" />
        <el-step :title="packageConstants.FILL_DATA_HELP" />
        <el-step :title="generalConstants.UPLOAD_FILE" />
        <el-step :title="packageConstants.PACKAGE_STATISTICS" />
        <el-step :title="generalConstants.FINISH" class="text-end"/>
      </el-steps>
      <el-form
        :model="downloadFormData"
        :rules="rules"
        ref="downloadFormRef"
        class="w-100 mt-10"
      >
        <div class="row text-center" v-if="active == 0">
          <div class="col-md-12 fv-row">
            <label class="fs-6 fw-bold mb-2"> {{ generalConstants.SELECTPRODUCT }}</label>
            <el-form-item prop="productId" class="import-form-item">
              <el-select
                v-model="downloadFormData.productId"
                filterable
                :placeholder="generalConstants.SELECTPRODUCT"
                size="large"
              >
              <el-option :label="prodcutsData.product_name" :value="prodcutsData.product_id" :key="prodcutsData"  v-for="prodcutsData in productData"></el-option>
              </el-select>
            </el-form-item>
            <button size="large" class="btn btn-primary mt-5" type="button" :loading="loading" @click="downloadFile('xlsx')">{{ generalConstants.DOWNLOAD_EXCEL_FILE }}</button>
            <button size="large" class="btn btn-primary mt-5 ms-3" type="button" :loading="loading" @click="downloadFile('csv')">{{ generalConstants.DOWNLOAD_CSV_FILE }}</button>
          </div>
        </div>
      </el-form>
      <span v-if="active == 1">
        <table class="table table-row-dashed fs-6 datatable border gy-2 gs-2">
          <thead class="m-2">
            <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0">
              <th>{{ packageConstants.EXCEL_COLUMN }}</th>
              <th>{{ generalConstants.DESCRIPTION }}</th>
              <th>{{ packageConstants.REQUIRED }}</th>
              <th>{{ packageConstants.UNIQUE }}</th>
            </tr>
          </thead>
          <tbody class="fw-bold text-gray-600">
            <tr>
              <td>sku</td>
              <td>Enter System SKU.<br>Every System SKU should be unique.</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td class="text-success">{{ packageConstants.YES }}</td>
            </tr>
            <tr>
              <td>package_type</td>
              <td>Enter Package Type as below,<br>internal - Internal Package<br>external - External Package</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
            <tr>
              <td>package_code</td>
              <td>Enter Package Code.<br>Every package code should be unique.</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td class="text-success">{{ packageConstants.YES }}</td>
            </tr>
            <tr>
              <td>weight_class</td>
              <td>Enter Weight Unit.<br>LBS<br>KG</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
            <tr>
              <td>max_weight</td>
              <td>Enter Max single package weight.<br>Only digits should be used for box weight.</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
            <tr>
              <td>box_weight</td>
              <td>Enter Box Weight.<br>Only digits should be used for box weight.</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
            <tr>
              <td>length_class</td>
              <td>Enter Length Unit.<br>IN<br>CM</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
            <tr>
              <td>package_length</td>
              <td>Enter Package Length.<br>Only digits should be used for box weight.</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
            <tr>
              <td>package_width</td>
              <td>Enter Package Width.<br>Only digits should be used for box weight.</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
            <tr>
              <td>package_height</td>
              <td>Enter Package Height.<br>Only digits should be used for box weight.</td>
              <td class="text-danger">{{ packageConstants.YES }}</td>
              <td>{{ packageConstants.NO }}</td>
            </tr>
          </tbody>
        </table>
      </span>
      <el-form
        @submit.prevent="uploadExcelData()"
        :model="uploadFormData"
        :rules="rules"
        ref="uploadFormRef"
        class="w-100 mt-5"
      > 
        <div class="row text-center" v-if="active == 2">
          <div class="col-md-12 fv-row">
            <label class="fs-6 fw-bold">{{ packageConstants.SELECT_PACKAGE_EXCEL }}</label>
            <el-form-item prop="excelFiles" class="import-form-item">
              <el-upload
                v-model="uploadFormData.excelFiles"
                :auto-upload="false"
                :limit="2"
                :on-remove="handleRemove"
                v-on:change="handleExceed"
                :file-list="fileList"
                drag
                class="upload-exel"
                >
                <div class="fv-row">
                  <div class="dropzone" id="kt_dropzonejs_example_1">
                      <div class="dz-message needsclick">
                          <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                          <div class="ms-4">
                              <h3 class="fs-5 fw-bolder text-gray-900 mb-1 mt-4" v-html="generalConstants.UPLOADFILEPLACETEXT"></h3>
                          </div>
                      </div>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <span v-if="active == 3">
          <div class="row">
            <div class="col-md-2 mb-1">
              <div class="col bg-light-primary px-6 py-8 rounded-2">
                <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                  <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                </span>
                <span class="text-primary fw-bold fs-6"> {{ generalConstants.TOTAL_ROWS }} <h1 class="text-primary"><strong>{{ statistics.total }}</strong></h1></span>
              </div>
            </div>
            <div class="col-md-2 mb-1">
              <div class="col bg-light-success px-6 py-8 rounded-2">
                <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                  <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                </span>
                <span class="text-success fw-bold fs-6"> {{ packageConstants.NEW_MASTER_PACKAGES }} <h1 class="text-success"><strong>{{ statistics.addedMasterPackage }}</strong></h1></span>
              </div>
            </div>
            <div class="col-md-2 mb-1">
              <div class="col bg-light-success px-6 py-8 rounded-2">
                <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                  <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                </span>
                <span class="text-success fw-bold fs-6"> {{ packageConstants.EXISTING_MASTER_PACKAGES }} <h1 class="text-success"><strong>{{ statistics.existingMasterPackage }}</strong></h1></span>
              </div>
            </div>
            <div class="col-md-2 mb-1">
              <div class="col bg-light-success px-6 py-8 rounded-2">
                <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                  <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                </span>
                <span class="text-success fw-bold fs-6"> {{ packageConstants.ASSIGNED_INTERNAL_PACKAGES }} <h1 class="text-success"><strong>{{ statistics.addedInternalPackage }}</strong></h1></span>
              </div>
            </div>
            <div class="col-md-2 mb-1">
              <div class="col bg-light-success px-6 py-8 rounded-2">
                <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                  <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                </span>
                <span class="text-success fw-bold fs-6"> {{ packageConstants.ASSIGNED_EXTERNAL_PACKAGES }} <h1 class="text-success"><strong>{{ statistics.addedExternalPackage }}</strong></h1></span>
              </div>
            </div>
            <div class="col-md-2 mb-1">
              <div class="col bg-light-danger px-6 py-8 rounded-2">
                <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                  <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                </span>
                <span class="text-danger fw-bold fs-6"> {{ generalConstants.SKIPPED_ROWS }} <h1 class="text-danger"><strong>{{ statistics.skipped }}</strong></h1></span>
              </div>
            </div>
          </div>
        </span>
      </el-form>
      <span v-if="active == 5">
        <el-row class="justify-content-center">
          <el-col :md="10" class="text-center">
            <div class="alert alert-success d-flex flex-column flex-sm-row p-5 mb-10 justify-content-center">
              <span class="svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                  <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                </svg>
              </span>
              <div class="d-flex flex-column pe-0 pe-sm-10">
                  <h4 class="mb-2 text-center">{{ generalConstants.SUCCESS }}</h4>
                  <span class="text-center">{{ packageConstants.PACKAGEIMPORTMESSAGE }}</span>
              </div>
            </div>
            <button type="button" size="large" class="btn btn-primary" @click.prevent="$router.push({ name: 'shipping-packages', params: {id: downloadFormData.productId}})">{{ packageConstants.PACKAGEIMPORTREDIRECTBTN }}</button>
            <button type="button" size="large" class="btn btn-primary ms-2" @click.prevent="$router.push({ name: 'import-packages-history', params: {id: downloadFormData.productId}})">{{ packageConstants.PACKAGEIMPORTHISTORYREDIRECTBTN }}</button>
          </el-col>
        </el-row>
      </span>
      <div class="col-md-12 mt-10 d-flex justify-content-between">
        <div>
          <button type="button" size="large" v-bind:class="btnHide" :disabled="disabled" v-if="active > 0" class=" btn btn-primary me-2" :loading="loading" @click="back">{{generalConstants.PREVIOUS}}</button>
        </div>
        <div>
          <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" v-if="(active < 4 && statistics.length == 0) || (active == 3 && (statistics.addedInternalPackage > 0 || statistics.addedExternalPackage > 0))" @click="actioncall()">{{generalConstants.NEXT}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
          <button type="button" size="large" class=" btn btn-secondary me-2" v-bind:class="btnHide" @click.prevent="$router.push({ name: 'shipping-packages'})">{{generalConstants.CANCEL}}</button>
        </div>
      </div>
      <div class="col-md-12 mt-5 bg-light p-2">
        <span class="text-danger rounded-1">{{ packageConstants.IMPORT_PACKAGE_HELP }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import axios from "axios";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
  name: "shipping-package-import",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const btnHide = ref();
    const loading = ref(false);
    const file = ref();
    const active = ref(0);
    const disabled = ref(false);
    const excelData = ref([]);
    const productData = reactive([]);
    const uploadFormRef = ref(null);
    const downloadFormRef = ref(null);
    const packageCount = ref(0);
    const generalConstants = globalConstant.general;
    const packageConstants = globalConstant.package;
    const fileList = ref([]);
    const statistics = ref([]);
    const downloadFormData = ref({
      productId: "",
    });

    const uploadFormData = ref({
      excelFiles: "",
    });

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "cancel"){
        router.push('/shipping-packages');
      }
    })

    const rules = ref({
      productId: [ { required: true, message: generalConstants.PRODUCTREQUIRED, trigger: "change" } ],
      excelFiles: [ { required: true, message: generalConstants.EXCELFILEREQUIRED, trigger: "change" } ],
    });

    const handleRemove = (e) => {
      uploadFormData.value.excelFiles = '';
    }

    const handleExceed = (e) => {
      if(fileList.value.length > 1){
        fileList.value.splice(0, 1);
      }
      file.value = e.raw;
      uploadFormData.value.excelFiles = e.raw;
    }

    const next = () => {
      active.value++;
    }
    
    const back = () => {
      active.value--;
      statistics.value = [];
    }

    const actioncall = () => {
      if(active.value > 1) {
        uploadExcelData();
      } else {
        next();
      }
    }

    //Download excel file of shipping packages
    const downloadFile = (extension) => {
      if(extension != "" && (extension == 'csv' || extension == 'xlsx')){
        if (!downloadFormRef.value) return;
        disabled.value = true;
        downloadFormRef.value.validate((valid) => {
          if (!valid) return false;
          axios({
            url: 'shipping-packages/export-shipping-packages',
            method: 'POST',
            data: {
              productId: downloadFormData.value.productId,extension:extension
            },
            responseType: (extension == 'csv') ? '' : 'arraybuffer',
          }).then((response) => {
            let blob = new Blob([response.data], {
                type: (extension == 'csv')?'text/csv;charset=UTF-8':''
            })
            let link = document.createElement('a')
            link.setAttribute('target', '_blank')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'shipping_packages_'+Date.now()+'.'+extension
            link.click()
            disabled.value = false;
          })
          .catch((error) => {
            disabled.value = false;
            let message='';
            if (typeof (error.response) !== 'undefined') {
              message = error.response.data.message;
            } else if (typeof (error.message) !== 'undefined') {
              message = error.message;
            } else {
              message = "Something went wrong";
            }
            notificationFire(message, "error");
          });
          return false;
        });
      }else{
        notificationFire('Something went wrong.', "error");
      }
    }

    //Fetch Active products
    const getProducts = () => {
      ApiService.query('get-active-products')
      .then(({ data }) => {
        if (data) {
          data.data.forEach((obj) => {
            productData.push({
              product_id: obj.product_id,
              product_name: obj.name,
            });
          });
        }
      })
      .catch((error) => {
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = "Something went wrong";
        }
        notificationFire(message, "error");
      });
    }

    //Upload excel file for import packages
    const uploadExcelData = async (formEl) => {
      if (!uploadFormRef.value) return;

      uploadFormRef.value.validate((valid) => {
        if (!valid) return false;
        
        if(active.value > 1){
          loading.value = true;
          const config = {
            headers: { 'content-type': 'multipart/form-data' }
          }
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('steps', active.value);
          if(active.value == 3){
            if(sessionStorage.getItem("filename")){
              formData.append('filename', sessionStorage.getItem("filename"));
            }
            if(sessionStorage.getItem("fileext")){
              formData.append('fileext', sessionStorage.getItem("fileext"));
            }
          }
          axios.post('shipping-packages/import-packages', formData, config)
          .then(({ data }) => {
            loading.value = false;
            if (active.value == 2) {
              sessionStorage.setItem("filename", data.data.importFile['filename']);
              sessionStorage.setItem("fileext", data.data.importFile['fileext']);
              statistics.value = data.data;
              active.value = 3;
            } else {
              notificationFire(data.message, "success");
              packageCount.value = data.data;
              active.value = 5;
              btnHide.value = 'd-none';
            }
          })
          .catch((error) => {
            loading.value = false;
            let message='';
            if (typeof (error.response) !== 'undefined') {
              message = error.response.data.message;
            } else if (typeof (error.message) !== 'undefined') {
              message = error.message;
            } else {
              message = "Something went wrong";
            }
            notificationFire(message, "error");
          });
        }
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'cancel','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and get the products
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Shipping Packages", "path": "shipping-packages"}, { name: formatText(route.name), active: true }]);
      generateHeaderToolbarButtons();
      getProducts();
    })

    return {
      file,
      btnHide,
      loading,
      rules,
      active,
      disabled,
      back,
      excelData,
      uploadExcelData,
      uploadFormRef,
      uploadFormData,
      handleExceed,
      handleRemove,
      productData,
      downloadFile,
      downloadFormData,
      downloadFormRef,
      packageCount,
      generalConstants,
      packageConstants,
      next,
      actioncall,
      fileList,
      statistics
    };
  },
};
</script>
<style>
  .import-form-item .el-form-item__content {
    justify-content: center;
  }
  .import-form-item .el-form-item__error {
    right: 0;
  }
  .upload-exel .el-upload-dragger{
    border: none !important;
    height: auto;
    padding: 1rem;
  }
  .steps .el-step__icon{
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .steps .el-step__head.is-process{
    color: #009ef7;
    font-weight:700;
  }
  .steps .el-step.is-horizontal .el-step__line{
    top:50%;
    display: block;
  }
  .steps .el-step__head.is-process {  
  border-color: #009ef7;
  }
  .steps .el-step__main{
    color: #009ef7;
  }
  .steps .el-step__title.is-process{
    color: #009ef7 !important;
  }
  .steps  .el-step__title.is-success {
    color:#009ef7;
    font-weight: 700;
  }
  .steps .el-step__head.is-success {
    color:#fff;
  }
  .steps  .el-step__head.is-success .el-step__line{
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success .el-step__icon{
    border-color: #009ef7;
    background-color: #009ef7;
  }
  .steps  .el-step__head.is-success{
    border-color: #009ef7;
  }
  .steps .form-check-input {
    width: 1.30em;
    height: 1.30rem;
    margin-right: 10px;
  }
</style>