import { ElNotification } from 'element-plus';

//We have this 4 types of error message :- success , warning , info , error
export function notificationFire(Msg,Type='success',Title='') {
  if (typeof Msg == "object") {
    let topStartPoint = 50;
    let increasePoint = 80;
    for (const key in Msg) {
      ElNotification({ message: Msg[key], type: 'error', title: '',   offset: topStartPoint})
      topStartPoint += increasePoint;
    }
  } else {
    ElNotification({ message: Msg, type: Type, title: Title, dangerouslyUseHTMLString: true})
  }
  
}


