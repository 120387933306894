import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

/**
 * Sets current page action buttons
 * @param {Array<string>} breadcrumbs Current page buttons
 */
export const setCurrentPageActionButtons = (
  actionButtons: Array<string>
): void => {
  store.dispatch(Actions.SET_ACTIONBUTTONS_ACTION, {
    pageActionButtons: actionButtons,
  });
};
